// Ensure names are CAPITAL version of Sanity Name.
// So 'Purple' in Sanity would be titled 'PURPLE' here.
export const COLOR = {
  BLACK: '#000000',
  GRAY: '#FAF9F6',
  VIDEO_TAN: '#ebe4d8',
  VIOLET: '#7167CE',
  ORANGE: '#FF6434',
  WHITE: '#FFFFFF',
  PURPLE: '#5E36FF',
  PURPLE_HOVER: '#4D2BCC',
  ROADCAST_ORANGE: '#CF4F2D',
  ROADCAST_ORANGE_HOVER: '#A84124',
}

export const THEME_COLORS = {
  BLACK: 'black',
  WHITE: 'white',
}

export const CONTENTFUL_COLOR = {
  BLACK: 'Black',
  GRAY: 'Gray',
  TAN: 'Tan',
  VIOLET: 'Violet',
  ORANGE: 'Orange',
  WHITE: 'White',
  GOLD: 'Gold',
}
