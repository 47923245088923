import { MQ } from 'utils/constants'

// Value is designs @ 1440px
const setScalableValue = (value) => {
  const scaling = (value / 1440) * 100
  const maxValue = 3840 * (scaling / 100)
  if (value === undefined) return ''
  if (value === 0 || value === 'auto') return value
  return `clamp(${value}px, ${scaling}vw, ${maxValue}px)`
}

export const SPACING = {
  width: '90%',
  [MQ.TABLET_LARGE]: {
    width: '80.8333%',
  },
}
export const SPACING_SMALL = {
  width: '95%',
  [MQ.TABLET]: {
    width: '97.22222%',
  },
}
export const NAV_SPACING = {
  width: '76.875%',
  [MQ.TABLET]: {
    width: '90.13889%',
  },
}

export const MARGIN = {
  margin: `${setScalableValue(100)} auto`,
}
