export const Z_INDEX = {
  BACKGROUND: 1,
  UNDERLAY: 10,
  CONTENT: 20,
  HOVER_BUTTON: 25,
  OVERLAY: 30,
  NAV_FILTER: 80,
  BASE_CONTENT: 90,
  NAV: 100,
  HEADER: 110,
  MODAL_BACKGROUND: 120,
  MODAL: 130,
}
