const redirectsData = require('./redirects.json')

const proposals = [
  '/artclever/',
  '/johanssonwing-2023/',
  '/robertsonengineering/',
  '/timeforart/',
  '/riffboard/',
  '/enne/',
  '/olive-branch/',
  '/NCPTF/',
  '/e9/',
  '/r&o-fhoto/',
  '/squint/',
  '/goodwolf/',
  '/darkhorse/',
  '/omwah-retainer/',
  '/hhs/',
  '/MTS/',
  '/GVC/',
  '/opio/',
  '/lulacgrows/',
  '/oro-y-plata/',
  '/naturebaby/',
  '/glacierchildrensmuseum/',
  '/ginn-kunze/',
  '/cityofburien-june2022/',
  '/nvms-video/',
  '/AC/',
  '/sthelens/',
  '/DC/',
  '/romano-marketing/',
  '/northvalley/',
  '/nodal/',
  '/family-solutions/',
  '/PAC/',
  '/whitefish-kits/',
  '/Haven/',
  '/cityofburien/',
  '/VWS/',
  '/cpaforward/',
  '/koval/',
  '/rms/',
  '/stephaniemeyers/',
  '/coaching-hhpr/',
  '/Lulac/',
  '/Lincoln/',
  '/Watershed-web-refresh/',
  '/watershedmap/',
  '/cascadiadevelopmentpartners/',
  '/marinasocial/',
  '/omwah/',
  '/deacon/',
  '/taradivina/',
  '/uphill/',
  '/p-coaching-bumo/',
  '/entrysentry/',
  '/speechtherapy/',
  '/vida/',
  '/p-coaching-bridgeview/',
  '/p-ripple-coaching/',
  '/p-cityofduvall-oct21/',
  '/rollpros/',
  '/epik/',
  '/cornerstone/',
  '/camphope/',
  '/Davidson/',
  '/HQ/',
  '/juliacarlson/',
  '/omdsocial/',
  '/LIT/',
  '/pointnorth/',
  '/fasterpermits/',
  '/onda/',
  '/ripple/',
  '/comfortsystems/',
  '/rifftogether/',
  '/longstreetpm/',
  '/proposal-johanssonwing/',
  '/cityofduvall/',
  '/CFA/',
  '/CREDC/',
  '/wiaa/',
  '/T&C/',
  '/VIC/',
  '/HydroStar/',
  '/Westfield/',
  '/vibrant-roots-branding/',
  '/V75/',
]

const proposalRedirect = () =>
  proposals.map((prop) => {
    return {
      fromPath: `${prop}`,
      toPath: `https://archive.riff.agency${prop}`,
      statusCode: 200,
    }
  })
const jsonRedirect = () =>
  redirectsData.map((path) => {
    return {
      fromPath: path.fromPath,
      toPath: path.toPath,
    }
  })
exports.redirects = [...proposalRedirect(), ...jsonRedirect()]
