import { withPrefix } from 'gatsby'

export const CURSOR = {
  DEFAULT: `url(${withPrefix('/cursors/cursor.svg')}), auto`,
  POINTER: `url(${withPrefix('cursors/cursor.svg')}), pointer`,
}

export const CURSOR_PURPLE = {
  DEFAULT: `url(${withPrefix('cursors/cursor_purple.svg')}), auto`,
  POINTER: `url(${withPrefix('cursors/cursor_purple.svg')}), pointer`,
}

export const CURSOR_WHITE = {
  DEFAULT: `url(${withPrefix('cursors/cursor_white.svg')}), auto`,
  POINTER: `url(${withPrefix('cursors/cursor_white.svg')}), pointer`,
}
