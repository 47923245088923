import { create } from 'zustand'
import { THEME_COLORS } from 'utils/constants'

export const useThemeStore = create((set) => ({
  backgroundColor: THEME_COLORS.BLACK,
  color: THEME_COLORS.WHITE,
  toggleTheme: () => set((state) => ({ theme: state.theme })),
}))

/**
 * Converts a theme name into corresponding CSS variables.
 *
 * @param {('light' | 'dark')} theme - The name of the theme, can be 'dark' or 'light'.
 * @returns {Object} An object containing CSS variables for the given theme.
 * @property {string} backgroundColor - The background color for the theme.
 * @property {string} color - The text color for the theme.
 */
export const themeToVars = (theme) => {
  return theme?.toLowerCase() === 'dark'
    ? { backgroundColor: THEME_COLORS.BLACK, color: THEME_COLORS.WHITE }
    : { backgroundColor: THEME_COLORS.WHITE, color: THEME_COLORS.BLACK }
}
