exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-riffing-feed-jsx": () => import("./../../../src/pages/riffing/feed.jsx" /* webpackChunkName: "component---src-pages-riffing-feed-jsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "slice---src-components-organisms-footer-footer-jsx": () => import("./../../../src/components/organisms/Footer/Footer.jsx" /* webpackChunkName: "slice---src-components-organisms-footer-footer-jsx" */),
  "slice---src-components-organisms-header-header-jsx": () => import("./../../../src/components/organisms/Header/Header.jsx" /* webpackChunkName: "slice---src-components-organisms-header-header-jsx" */)
}

